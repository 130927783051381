import $ from '../../node_modules/jquery/dist/jquery.min';

var FMScratch = FMScratch || {};

export default FMScratch.Collapsibles = {

    init:function(){
        this.collapse();
    },

    collapse:function(){
        let collapsibles = $('.wp-block-group.collapse');

        for (var i = 0; i < collapsibles.length; i++) {
            $( collapsibles[i] ).attr("id","collapsible-"+i);
            let thisBtn = $( collapsibles[i] ).next().find('.wp-block-button__link');
            $(thisBtn).attr({
                "data-toggle" : "collapse",
                "href" : "#collapsible-"+i,
                "aria-expanded" : "false",
                "aria-controls" : "collapsible-"+i,
            });
            $(thisBtn).on('click',function(e){
                $(this).parent().toggleClass('readless');
                $(this).text($(this).text() == 'Read less' ? 'Read more' : 'Read less');
            });
        };
    },

};
