// https://console.cloud.google.com/google/maps-apis
const API_KEY = `AIzaSyBswp2tlWtPjmhA5rIfX1tbnjSRtAmZx3A`;
const CALLBACK_NAME = `gmapsCallback`;

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;

const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default function init() {

  if (initialized) return initPromise;

  initialized = true;
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

  const script = document.createElement(`script`);
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}&libraries=geometry,places`;
  script.onerror = rejectInitPromise;
  document.querySelector(`head`).appendChild(script);

  return initPromise;
}
