<template>

    <div class="map-compenent-container">

        <div class="searchbar">

            <div class="input-group mb-2 mt-4">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-search" aria-hidden="true"></i></span>
              </div>
              <input v-model="searchZip" type="text" class="form-control search-field" placeholder="Enter a Zip Code" aria-label="Enter a Zip Code" aria-describedby="searchBtn" v-on:keyup.enter="zoomToZip">
              <div class="input-group-append">
                <button class="btn has-green-background-color" id="searchBtn" type="button" v-on:click="this.zoomToZip">SEARCH</button>
              </div>
            </div>
        </div>
        <div v-if="this.outputData.length">{{ outputData }}</div>
        <div id="map"/>
        <div class="results wp-block-columns">
            <div class="wp-block-column" v-for="(location, index) in this.closestThree" v-if="!doUpdateData">
                <span class="marker-label">{{ markerLabels[index] }}</span>
                <strong class="place-name d-block">{{ location.title }}</strong>
                <ul class="list-unstyled px-0">
                    <li>{{ location.vicinity }}</li>
                    <li><a v-if="location.formatted_phone_number" :href="`tel:${location.formatted_phone_number}`" class="tel">{{ location.formatted_phone_number }}</a></li>
                    <li><a v-if="location.website" :href="location.website" target="_blank">Website</a><br/></li>
                    <li v-if="location.opening_hours.weekday_text.length">Open {{ location.opening_hours.weekday_text[today] }}</li>
                </ul>
                <a v-if="!location.hide_directions" :href="destinationStr(location)" target="_blank" class="flex-grow-1 w-100 directions-btn">GET DIRECTIONS</a>
            </div>
        </div>

    </div>


</template>
<script>

    import gmapsInit from './js/gmaps';
    import locations from "./js/data/mapdata.js";


    export default {
      name: `App`,
      data: function() {
        return {
            closestThree: [ locations[0], locations[23], locations[32]],
            doUpdateData: false,
            doDupeCheck:false,
            map:null,
            markers: new Array(),
            markerLabels: ['A','B','C'],
            outputData: new Array(),
            searchZip: '',
            startLatLng: { "lat" : 38.3498195, "lng" : -81.6326234 },
            today: (( new Date().getDay() + 6) % 7),
        };
      },

      methods: {
            destinationStr: function(location){
                let destination = encodeURI( location.title + ' ' + location.street + ' ' + location.city + ' ' + location.state );
                let destinationPlaceId = location.place_id;
                return `https://www.google.com/maps/dir/?api=1&destination=${destination}&destination_place_id=${destinationPlaceId}`;
            },
            dupeCheck: function(){
                let placeIds = new Array();
                for (let i = 0; i < locations.length; i++) {
                    placeIds.push( locations[i].place_id );
                };
                console.log('total ids: ' + placeIds.length);

                let uniqueIDS = new Set(placeIds);

                if( uniqueIDS.size !== placeIds.length ){
                    console.log('duplicates exist');
                    console.log('unique ID count: ' + uniqueIDS.size);
                }
                const dupes = placeIds.reduce((acc, v, i, arr) => arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc, []);
                console.log( dupes );
            },
            getLocationsData: function(map, locations, outputData){

                let i = 1, service = new google.maps.places.PlacesService(map);

                (function placeLoop(i) {

                  setTimeout(function() {
                    console.log('getting geocode info - ' + i + ' of ' + locations.length );
                    let location = locations[i-1];
                    let q = `${location.title} ${location.street} ${location.city} ${location.zip}`;
                    let request = {
                      query: q,
                      fields: ['name', 'geometry', 'place_id'],
                    };

                    service.findPlaceFromQuery(request, function(results, status) {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {

                            // console.log(results[0]); // sometimes a place will have more than one result. we can assume first is good

                            let detailsRequest = {
                              placeId: results[0].place_id,
                              fields: ['formatted_phone_number', 'website', 'vicinity', 'opening_hours'],
                              language: 'en',
                            };

                            service.getDetails(detailsRequest, function(detailsResults, status){
                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                    console.log(detailsResults);
                                    let weekdayText = [];
                                    if( detailsResults.opening_hours ){
                                        weekdayText = detailsResults.opening_hours.weekday_text;
                                    }
                                    // console.log(detailsResults.opening_hours.weekday_text);
                                    outputData.push(
                                        {
                                        "title": location.title,
                                        "street": location.street,
                                        "city": location.city,
                                        "state": location.state,
                                        "zip": location.zip,
                                        "formatted_phone_number": detailsResults.formatted_phone_number,
                                        "website": detailsResults.website,
                                        "vicinity": detailsResults.vicinity,
                                        "place_id": results[0].place_id,
                                        "opening_hours": {
                                            "weekday_text": weekdayText,
                                        },
                                        "position": {
                                            "lat": results[0].geometry.location.lat(),
                                            "lng": results[0].geometry.location.lng()
                                            }
                                        }
                                    );
                                }
                            });
                        }
                    });

                    if (--i) { placeLoop(i); }
                    else {
                        // console.log("last one... ");
                        setTimeout(function() {
                            console.log( JSON.stringify(outputData) );
                            }, 3000);
                    }
                  }, 1000)
                })(2);

            },
            zoomToZip: function(){
                // console.log(this.searchZip);
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: `${this.searchZip} West Virginia USA`}, (results, status) => {
                  if (status !== `OK` || !results[0]) {
                    throw new Error(status);
                  }
                  // this.map.setCenter(results[0].geometry.location);
                  // this.map.setZoom(12);
                  this.findClosestThree( results[0].geometry.location );
                  this.setBoundsToMarkers(this.closestThree);
                });


            },
            findClosestThree: function( currentLatLng ){

                // console.log('finding closest three ');

                let markers_distances = [];
                for (let i = 0; i < this.markers.length; i++) {
                    var d = google.maps.geometry.spherical.computeDistanceBetween(this.markers[i].position, currentLatLng);

                    markers_distances[i] = {
                        distance: d,
                        marker: this.markers[i]
                    }
                    this.markers[i].setLabel('');

                }
                let closest_markers = markers_distances.sort((a, b) => {return a.distance-b.distance}).slice(0,3);

                this.closestThree = closest_markers.map((item) => {return item.marker});

                for (let i = 0; i < this.closestThree.length; i++) {
                    this.closestThree[i].setLabel(this.markerLabels[i]);
                };

            },
            setBoundsToMarkers: function(markers){

                let bounds = new google.maps.LatLngBounds();
                for(let i=0;i<markers.length;i++) {
                   bounds.extend(markers[i].getPosition());
                }

                //center the map to the geometric center of all markers
                this.map.setCenter(bounds.getCenter());

                this.map.fitBounds(bounds);



            }
      },
      async mounted() {
        try {
            const google = await gmapsInit();
            const map = new google.maps.Map( document.getElementById('map') );
            this.map = map;

            const startpoint = {
                    "geometry" : {
                        "location" : this.startLatLng,
                        "viewport" :  new google.maps.LatLngBounds(
                                            new google.maps.LatLng(38.28113889999999,-81.7277771 ),
                                            new google.maps.LatLng(38.4057589,-81.55931 )
                                      ),

                    }
            };

            map.setCenter(startpoint.geometry.location);
            // map.fitBounds(startpoint.geometry.viewport);
            map.setZoom(15);

          this.markers = locations
            .map((location) => {
              const marker = new google.maps.Marker({ ...location, map });
              marker.addListener(`click`, () => markerClickHandler(marker));
              return marker;
            });

            // console.log("Markers added: " + this.markers.length );


          let infowindow = new google.maps.InfoWindow();

          const markerClickHandler = (marker) => {
            // map.setZoom(10);
            // map.setCenter(marker.getPosition());
            let destination = this.destinationStr(marker);
            let hours = marker.opening_hours.weekday_text.length ? marker.opening_hours.weekday_text[this.today] + '<br/>' : '';
            let tel = marker.formatted_phone_number ? `<a href="tel:${marker.formatted_phone_number}" class="tel">${marker.formatted_phone_number}</a><br/>` : '';
            let websiteStr = marker.website ? `<a href="${marker.website}" target="_blank">Website</a><br/>` : '';
            let dirLink = marker.hide_directions  ? '' : `<a href="https://www.google.com/maps/dir/?api=1&destination=${destination}" target="_blank">Get Directions</a>`;

            let contentString =   `<div class="info-window-content">
                                        <strong class="place-name">${marker.title}</strong><br/>
                                        ${marker.vicinity}<br/>
                                        ${hours}
                                        ${tel}
                                        ${websiteStr}
                                        ${dirLink}
                                    </div>`;
            infowindow.setContent(contentString);
            infowindow.open(map, marker);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
             'event': 'pin_click',
             'place': marker.title
             });

          };


          if( !this.doUpdateData ){

            this.findClosestThree( new google.maps.LatLng(this.startLatLng.lat, this.startLatLng.lng) );
            this.setBoundsToMarkers(this.closestThree);

            let _this = this;
            let timer;
            map.addListener('idle', function(e) {
                window.clearTimeout(timer);
                let timer = window.setTimeout(_this.findClosestThree( map.getCenter() ), 3000);
            });

          }

          if( this.doUpdateData ){ this.getLocationsData(map, locations, this.outputData); }
          if( this.doDupeCheck ){ this.dupeCheck(); }

        } catch (error) {
          console.error(error);
        }
      },
    };

</script>

