import $ from '../node_modules/jquery/dist/jquery.min';
import 'popper.js';
import 'bootstrap';
import GDPR from './js/GDPR';
import Collapsibles from './js/Collapsibles';
import ModalHelper from './js/ModalHelper';
import StatAnim from './js/StatAnim';

(function($) {
    GDPR.init();
    Collapsibles.init();
    ModalHelper.init();
    StatAnim.init();
})(  );