import $ from '../../node_modules/jquery/dist/jquery.min';

var FMScratch = FMScratch || {};

// because i can't add data attributes in WP by default
export default FMScratch.ModalHelper = {

    init:function(){
        // console.log('Modals');
        this.doModals();

        // modal event listeners not working for some reason.
        $('button.close', '.modal').on('click', function(){
            // kill vid
            $("#vidIframe").attr('src','');
        } );

    },

    doModals:function(){

        let vidLinks = $('.wp-block-image.video-link');

        for (var i = 0; i < vidLinks.length; i++) {
            let thisBtn = $( vidLinks[i] ).find('a.video-link');
            let vidURL = $(thisBtn).attr('href');
            let iframe = $('#vidIframe');
            $(iframe).attr('src','');
            $(thisBtn).attr({
                "data-toggle" : "modal",
                "data-target" : "#vidModal",
            }).on('click', function(e){
                $(iframe).attr('src', vidURL);
            });

        };

    },

};
