import $ from '../../node_modules/jquery/dist/jquery.min';

var FMScratch = FMScratch || {};

export default FMScratch.StatAnim = {

    init:function(){
        if( $('.stat-animation').length ){
            this.initAnimations();
        }


    },

    initAnimations:function(){

        let stats = $('.stat-animation');
        let htmlStr =  [
                '<span class="count" data-to="50">0</span><span class="group"><span class="perc">%</span><span class="less">LESS</span></span>',
                '<span class="bignum">1</span><span class="group"><span class="death">DEATH</span><span class="every">EVERY <span class="count" data-to="16">0</span> MINUTES</span></span>',
                '<span class="count" data-to="2">0</span><span class="ex">X</span>',
        ];
        for (let i = 1; i <= htmlStr.length; i++) {
            let thisStat = $('.stat-animation.stat-'+i);
            let thisStr = htmlStr[i-1];
            $(thisStat).html(thisStr);
        };

        let _this = this;
        let _isAnimating = false;

        $(window).scroll(function(){

          function elementScrolled(elem)
          {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $(elem).offset().top;
            return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
          }

          if(elementScrolled('.stat-1')){
            if(!_isAnimating){
                _this.queueAnimations(false);
                _isAnimating = true;
            }
          }

        });


    },
    animateCount: function(el,dur=4000){
        // console.log('animating ' + el);
            let countEl = document.querySelector(el+' .count');
            $(countEl).prop('Counter',0).animate({
                Counter: $(countEl).data('to')
            }, {
                duration: dur,
                easing: 'linear',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });

    },

    queueAnimations: function(repeat){
        // console.log('queuing animations...');
        setTimeout(() => this.animateCount( '.stat-1' ), 1000);
        setTimeout(() => this.animateCount( '.stat-2' ), 3500);
        setTimeout(() => this.animateCount( '.stat-3',2000 ), 6500);
        if(repeat){
            setTimeout(() => this.queueAnimations(true), 23000);
        }

    }

};