export default

    [{
        "title": "Project Hope for Women & Children",
        "street": "1012 7th Ave",
        "city": "Huntington",
        "state": "WV",
        "zip": 25701,
        "formatted_phone_number": "(304) 696-4673",
        "website": "https://www.marshallhealth.org/services/addiction-medicine/project-hope-for-women-children/",
        "vicinity": "1012 7th Avenue, Huntington",
        "place_id": "ChIJBWGc9yMJRogRO7P3ng_DGqY",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.417293,
            "lng": -82.440551
        }
    }, {
        "title": "PROACT",
        "street": "800 20th Street",
        "city": "Huntington",
        "state": "WV",
        "zip": 25703,
        "formatted_phone_number": "(304) 696-8700",
        "website": "http://www.proactwv.org/",
        "vicinity": "800 20th Street, Huntington",
        "place_id": "ChIJ7zBCjtEJRogR_LGbhCG2A68",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 4:30 PM", "Tuesday: 8:00 AM – 4:30 PM", "Wednesday: 8:00 AM – 4:30 PM", "Thursday: 8:00 AM – 4:30 PM", "Friday: 8:00 AM – 4:30 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.4183605,
            "lng": -82.4206791
        }
    },  {
        "title": "Shenandoah - Berkeley County Day Report Center",
        "street": "800 Emmet Rousch Drive",
        "city": "Martinsburg",
        "state": "WV",
        "zip": 25401,
        "formatted_phone_number": "(304) 262-9831",
        "website": "http://dayreportcenter.berkeleywv.org/dayreporting/",
        "vicinity": "800 Emmett Rousch Drive, Martinsburg",
        "place_id": "ChIJGYkVofsCyokRHSgfr0XtCkk",
        "opening_hours": {
            "weekday_text": ["Monday: 8:30 AM – 8:00 PM", "Tuesday: 8:30 AM – 8:00 PM", "Wednesday: 8:30 AM – 8:00 PM", "Thursday: 8:30 AM – 8:00 PM", "Friday: 8:30 AM – 5:00 PM", "Saturday: 8:30 AM – 12:00 PM", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.4467037,
            "lng": -77.9659575
        },
    }, {
        "title": "Shenandoah - PHG Pendleton County Office",
        "street": "273 Mill Road",
        "city": "Franklin",
        "state": "WV",
        "zip": 26807,
        "formatted_phone_number": "(304) 358-7565",
        "website": "http://www.pendletoncountyhealthdepartment.org/",
        "vicinity": "273 Mill Road, Franklin",
        "place_id": "ChIJzcsL6aPNtIkRM0y3cUhIpCw",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 4:00 PM", "Tuesday: 8:00 AM – 4:00 PM", "Wednesday: 8:00 AM – 4:00 PM", "Thursday: 8:00 AM – 4:00 PM", "Friday: 8:00 AM – 4:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.64208989999999,
            "lng": -79.32780319999999
        }
    }, {
        "title": "Shenandoah - PHG Hardy County Office",
        "street": "79 Robert C. Byrd Industrial Park Road",
        "city": "Moorefield",
        "state": "WV",
        "zip": 26836,
        "vicinity": "79 Robert C. Byrd Industrial Park Road, Moorefield",
        "place_id": "Ej83OSBSb2JlcnQgQy4gQnlyZCBJbmR1c3RyaWFsIFBhcmsgUmQsIE1vb3JlZmllbGQsIFdWIDI2ODM2LCBVU0EiGhIYChQKEgnjwhWtcRS1iRFDzkwPEyo0SxBP",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 39.0714678,
            "lng": -78.9389578
        }
    }, {
        "title": "Shenandoah - East Ridge Health Systems",
        "street": "89 Sugar Hollow Road",
        "city": "Berkeley Springs",
        "state": "WV",
        "zip": 25411,
        "formatted_phone_number": "(304) 258-2889",
        "website": "http://eastridgehealthsystems.org/",
        "vicinity": "89 Sugar Hollow Road, Berkeley Springs",
        "place_id": "ChIJEwzjnosTyokROMfSETYEfkM",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 4:00 PM", "Tuesday: 8:00 AM – 4:00 PM", "Wednesday: 8:00 AM – 4:00 PM", "Thursday: 8:00 AM – 4:00 PM", "Friday: 8:00 AM – 4:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.6036216,
            "lng": -78.2378805
        }
    }, {
        "title": "Shenandoah - East Ridge Health Systems",
        "street": "235 S. Water Street",
        "city": "Martinsburg",
        "state": "WV",
        "zip": 25401,
        "formatted_phone_number": "(304) 263-8954",
        "website": "http://eastridgehealthsystems.org/",
        "vicinity": "235 South Water Street, Martinsburg",
        "place_id": "ChIJpVUjMDgCyokREoxjCIxQdhQ",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 7:00 PM", "Friday: 8:00 AM – 4:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.455194,
            "lng": -77.9618302
        }
    }, {
        "title": "Shenandoah - Pendleton Primary Care",
        "street": "299 Medical Court",
        "city": "Martinsburg",
        "state": "WV",
        "zip": 25401,
        "formatted_phone_number": "(304) 596-6343",
        "vicinity": "299 Medical Court, Martinsburg",
        "place_id": "ChIJXTxBEFMCyokR-6Pucg-OAoI",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 39.4719162,
            "lng": -77.9718276
        }
    }, {
        "title": "Shenandoah - Harpers Ferry Medicine",
        "street": "171 Taylor Street",
        "city": "Harpers Ferry",
        "state": "WV",
        "zip": 25425,
        "formatted_phone_number": "(304) 535-6343",
        "website": "http://wvumedicine.org/physicians/primary-care/harpers-ferry-family-medicine/",
        "vicinity": "171 Zachary Taylor Street, Harpers Ferry",
        "place_id": "ChIJSQb8-LECtokRRmFVFWNVhQE",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 8:00 PM", "Wednesday: 8:00 AM – 8:00 PM", "Thursday: 8:00 AM – 8:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.322175,
            "lng": -77.751085
        }
    }, {
        "title": "Shenandoah - Mountaineer Recovery Center",
        "street": "3094 Charles Town Road",
        "city": "Kearneysville",
        "state": "WV",
        "zip": 25430,
        "formatted_phone_number": "(304) 901-2070",
        "website": "https://mountaineerrecovery.com/",
        "vicinity": "3094 Charles Town Road, Kearneysville",
        "place_id": "ChIJwz422PYCyokRcF2WkpK3tOE",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 4:00 PM", "Tuesday: 8:00 AM – 4:00 PM", "Wednesday: 8:00 AM – 4:00 PM", "Thursday: 8:00 AM – 4:00 PM", "Friday: 8:00 AM – 4:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.40989709999999,
            "lng": -77.9156297
        }
    }, {
        "title": "Shenandoah - WVU East - Berkeley Medical Center",
        "street": "2500 Foundation Way",
        "city": "Martinsburg",
        "state": "WV",
        "zip": 25401,
        "formatted_phone_number": "(304) 264-1000",
        "website": "http://wvumedicine.org/berkeley/",
        "vicinity": "2500 Hospital Drive, Martinsburg",
        "place_id": "ChIJz1INSKkDyokRMmdZDnveNUY",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 39.4756413,
            "lng": -77.9803984
        }
    }, {
        "title": "Shenandoah Community Health",
        "street": "99 Tavern Road",
        "city": "Martinsburg",
        "state": "WV",
        "zip": 25401,
        "formatted_phone_number": "(304) 263-4999",
        "website": "http://www.shencommhealth.com/",
        "vicinity": "99 Tavern Road, Martinsburg",
        "place_id": "ChIJNS2GxlYCyokRSTlkpKeLz_k",
        "opening_hours": {
            "weekday_text": ["Monday: 8:30 AM – 7:00 PM", "Tuesday: 8:30 AM – 7:00 PM", "Wednesday: 8:30 AM – 7:00 PM", "Thursday: 8:30 AM – 7:00 PM", "Friday: 8:30 AM – 5:00 PM", "Saturday: 8:30 AM – 12:00 PM", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.4739402,
            "lng": -77.9664224
        }
    }, {
        "title": "Prestera Center - WVDHHR Boone County",
        "street": "156 Resource Lane",
        "city": "Danville",
        "state": "WV",
        "zip": 25081,
        "formatted_phone_number": "(304) 525-7851",
        "website": "https://www.prestera.org/",
        "vicinity": "376 Kenmore Drive, Danville",
        "place_id": "ChIJVUotEg0_T4gRZwXdB9ModAs",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.0834561,
            "lng": -81.84267129999999
        }
    }, {
        "title": "Prestera Center - WVDHHR Kanawha County",
        "street": "4190 Wshington Street",
        "city": "Charleston",
        "state": "WV",
        "zip": 25313,
        "formatted_phone_number": "(304) 746-2360",
        "website": "https://www.prestera.org/",
        "vicinity": "4190 Washington Street West, Charleston",
        "place_id": "ChIJFRJAs2rNSIgRvYv7lqF7USQ",
        "opening_hours": {
            "weekday_text": ["Monday: 8:30 AM – 5:00 PM", "Tuesday: 8:30 AM – 5:00 PM", "Wednesday: 8:30 AM – 5:00 PM", "Thursday: 8:30 AM – 5:00 PM", "Friday: 8:30 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.3817978,
            "lng": -81.7129593
        }
    }, {
        "title": "Prestera Center - Boone Memorial Hospital",
        "street": "701 Madison Avenue",
        "city": "Madison",
        "state": "WV",
        "zip": 25130,
        "formatted_phone_number": "(304) 369-1230",
        "website": "https://www.prestera.org/",
        "vicinity": "701 Madison Avenue, Madison",
        "place_id": "ChIJHVnlNTk-T4gRnr--kS_SCtE",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 38.0478383,
            "lng": -81.80638379999999
        }
    }, {
        "title": "Community Care of WV",
        "street": "78 Queens Alley Road",
        "city": "Rock Cave",
        "state": "WV",
        "zip": 26234,
        "formatted_phone_number": "(304) 924-6262",
        "website": "http://www.communitycarewv.org/rock-cave-wv1.html",
        "vicinity": "78 Queens Alley Road, Rock Cave",
        "place_id": "ChIJaYRRtd6zS4gRk61SkkOg6f4",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 8:00 PM", "Wednesday: 8:00 AM – 4:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.8390302,
            "lng": -80.34199009999999
        }
    }, {
        "title": "Huntington Addiction and Wellness Center",
        "street": "1236 5th Avenue",
        "city": "Huntington",
        "state": "WV",
        "zip": 25701,
        "formatted_phone_number": "(681) 204-5400",
        "website": "http://hawcrecovery.com/",
        "vicinity": "1236 5th Avenue, Huntington",
        "place_id": "ChIJuW8Ue-MJRogRYwnscS4_vVc",
        "opening_hours": {
            "weekday_text": ["Monday: 7:00 AM – 5:00 PM", "Tuesday: 7:00 AM – 5:00 PM", "Wednesday: 7:00 AM – 5:00 PM", "Thursday: 7:00 AM – 5:00 PM", "Friday: 7:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.420786,
            "lng": -82.4373166
        }
    }, {
        "title": "Recovery Point",
        "street": "2425 9th Avenue",
        "city": "Huntington",
        "state": "WV",
        "zip": 25705,
        "formatted_phone_number": "(304) 523-4673",
        "website": "http://www.recoverypointwv.org/",
        "vicinity": "2425 9th Avenue, Huntington",
        "place_id": "ChIJGdflKT0IRogRxLE6lF2JzK4",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: 9:00 AM – 4:00 PM", "Saturday: 8:00 AM – 5:00 PM", "Sunday: 8:00 AM – 5:00 PM"]
        },
        "position": {
            "lat": 38.418328,
            "lng": -82.412939
        }
    },{
        "title": "Recovery Point",
        "street": "900 20th Street",
        "city": "Huntington",
        "state": "WV",
        "zip": 25705,
        "formatted_phone_number": "(304) 523-4673",
        "website": "http://www.recoverypointwv.org/",
        "vicinity": "900 20th Street, Huntington",
        "place_id": "ChIJIW0MXSUIRogRv4W6wvTyn4A",
        "opening_hours": {
            "weekday_text": ["Monday: 8:30 AM – 5:00 PM", "Tuesday: 8:30 AM – 5:00 PM", "Wednesday: 8:30 AM – 5:00 PM", "Thursday: 8:30 AM – 5:00 PM", "Friday: 8:30 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.417224,
            "lng": -82.42057
        }
    }, {
        "title": "Thomas Health Hospital",
        "street": "4605 MacCorkle Avenue, SW",
        "city": "South Charleston",
        "state": "WV",
        "zip": 25309,
        "formatted_phone_number": "(304) 766-3600",
        "website": "http://www.thomaswv.org/",
        "vicinity": "4605 MacCorkle Avenue Southwest, South Charleston",
        "place_id": "ChIJE1z5gNEyT4gRV8N4hmms8_Y",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.3553422,
            "lng": -81.7266731
        }
    }, {
        "title": "Raleigh General Hospital",
        "street": "1710 Harper Road",
        "city": "Beckley",
        "state": "WV",
        "zip": 25801,
        "formatted_phone_number": "(304) 256-4100",
        "website": "http://www.raleighgeneral.com/",
        "vicinity": "1710 Harper Road, Beckley",
        "place_id": "ChIJPy4gsPDyTogR1wBX63F-qpc",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 37.7880211,
            "lng": -81.20177629999999
        }
    }, {
        "title": "Greenbrier Valley Medical Center",
        "street": "1320 maplewood Avenue",
        "city": "Ronceverte",
        "state": "WV",
        "zip": 24970,
        "formatted_phone_number": "(304) 647-6080",
        "website": "https://www.gvmc.com/emergency-department?utm_campaign=gmb-website&utm_medium=organic&utm_source=google",
        "vicinity": "1320 Maplewood Avenue, Ronceverte",
        "place_id": "ChIJ6dLGzwQ4TIgR17kVdIiUGiA",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 37.7726157,
            "lng": -80.4705355
        }
    }, {
        "title": "Greenbrier Physicians",
        "street": "1322 Maplewood Avenue",
        "city": "Ronceverte",
        "state": "WV",
        "zip": 24970,
        "formatted_phone_number": "(304) 647-1161",
        "website": "http://www.greenbrierphysicians.com/",
        "vicinity": "1322 Maplewood Avenue, Ronceverte",
        "place_id": "ChIJpxSjmxA4TIgRY82_plOW1-I",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 37.7725201,
            "lng": -80.46971169999999
        }
    }, {
        "title": "Seneca Health Services, Inc.",
        "street": "804 Industrial Park Road",
        "city": "Maxwelton",
        "state": "WV",
        "zip": 24957,
        "formatted_phone_number": "(304) 497-0500",
        "website": "http://shsinc.org/locations-2/",
        "vicinity": "804 Industrial Park Road, Maxwelton",
        "place_id": "ChIJh_MXeBdBTIgRevjgh-lS0Ko",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 37.8637018,
            "lng": -80.398609
        }
    }, {
        "title": "WVSOM Robert C. Byrd Clinic",
        "street": "1464 N. Jefferson Street",
        "city": "Lewisburg",
        "state": "WV",
        "zip": 24901,
        "formatted_phone_number": "(304) 645-3220",
        "website": "https://www.rcbclinic.com/",
        "vicinity": "1464 Jefferson Street North, Lewisburg",
        "place_id": "ChIJ00ifihNHTIgRuGHvcxJtTy4",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 37.8056969,
            "lng": -80.43615899999999
        }
    }, {
        "title": "Partnership of African American Churches",
        "street": "1511 Washington Street, E.",
        "city": "Charleston",
        "state": "WV",
        "zip": 25301,
        "formatted_phone_number": "(304) 343-2115",
        "website": "http://www.stmarkswv.org/",
        "vicinity": "900 Washington Street East, Charleston",
        "place_id": "ChIJ7fN8c8wsT4gRhdNxE4-cpM0",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.3504367,
            "lng": -81.6312305
        }
    }, {
        "title": "WVU Research",
        "street": "341 Spruce Street",
        "city": "Morgantown",
        "state": "WV",
        "zip": 26505,
        "formatted_phone_number": "(304) 292-8234",
        "website": "http://www.mphealthright.org/",
        "vicinity": "341 Spruce Street, Morgantown",
        "place_id": "ChIJ7fkf7317NYgRqYPcYeUwfDw",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 12:00 PM, 12:45 – 7:00 PM", "Tuesday: 8:00 AM – 12:00 PM, 12:45 – 4:30 PM", "Wednesday: 8:00 AM – 12:00 PM, 12:45 – 6:30 PM", "Thursday: 8:00 AM – 12:00 PM, 12:45 – 4:30 PM", "Friday: 8:00 AM – 12:00 PM, 12:45 – 4:30 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.629758,
            "lng": -79.954877
        }
    }, {
        "title": "WVU Research",
        "street": "1 Hospital Plaza",
        "city": "Grafton",
        "state": "WV",
        "zip": 26354,
        "formatted_phone_number": "(304) 265-0400",
        "website": "http://www.graftonhospital.com/",
        "vicinity": "1 Hospital Plaza, Grafton",
        "place_id": "ChIJE4x7zyaJSogRseg_cmxfwJI",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 39.3436685,
            "lng": -80.0250224
        }
    }, {
        "title": "WVU Research",
        "street": "725 Yokum Street",
        "city": "Elkins",
        "state": "WV",
        "zip": 26241,
        "formatted_phone_number": "(304) 636-3232",
        "website": "http://www.achcinc.org/",
        "vicinity": "725 Yokum Street, Elkins",
        "place_id": "ChIJNahj4a_jSogRI-mFgpP9vsY",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.9297032,
            "lng": -79.8636539
        }
    }, {
        "title": "WVU Research",
        "street": "3110 MacCorkle Avenue, E.",
        "city": "Charleston",
        "state": "WV",
        "zip": 25304,
        "formatted_phone_number": "(304) 347-1252",
        "website": "http://www.wvu.edu/",
        "vicinity": "3110 MacCorkle Avenue Southeast, Charleston",
        "place_id": "ChIJYz99y58sT4gR967gtKhnXRU",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.3302019,
            "lng": -81.6043107
        }
    }, {
        "title": "WVU Research",
        "street": "104 Alex Lane",
        "city": "Charleston",
        "state": "WV",
        "zip": 25304,
        "formatted_phone_number": "(304) 925-1171",
        "website": "http://www.wvu.edu/",
        "vicinity": "107 Alex Lane, Charleston",
        "place_id": "ChIJYXYaQqorT4gRSbck-cxU8NY",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 38.3053006,
            "lng": -81.56602629999999
        }
    },  {
        "title": "WVU Research",
        "street": "2500 Foundation Way",
        "city": "Martinsburg",
        "state": "WV",
        "zip": 25401,
        "formatted_phone_number": "(304) 264-9202",
        "vicinity": "2500 Foundation Way, Martinsburg",
        "place_id": "ChIJNWx4ka4DyokR6VoRJ-naKWY",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 39.4727891,
            "lng": -77.9806724
        }
    }, {
        "title": "Westbrook Health Services",
        "street": "314 Wells Street",
        "city": "Sistersville",
        "state": "WV",
        "zip": 26175,
        "website": "https://www.westbrookhealth.org/",
        "vicinity": "314 Wells Street, Sistersville",
        "place_id": "ChIJM8H2hFzjSYgRMgpHARRmWCA",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 39.5586939,
            "lng": -81.00415679999999
        }
    }, {
        "title": "Westbrook Health Services",
        "street": "122 Pinnell Street",
        "city": "Ripley",
        "state": "WV",
        "zip": 25271,
        "website": "https://www.westbrookhealth.org/",
        "vicinity": "122 Pinnell Street, Ripley",
        "place_id": "ChIJ_9E_0avvSIgR3jOUIlnmACc",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.822896,
            "lng": -81.729895
        }
    }, {
        "title": "Westbrook Health Services",
        "street": "186 Hospital Drive",
        "city": "Grantsville",
        "state": "WV",
        "zip": 26147,
        "formatted_phone_number": "(304) 927-5200",
        "website": "https://www.westbrookhealth.org/",
        "vicinity": "186 Hospital Drive, Grantsville",
        "place_id": "ChIJRT4U5HwaSYgR0q97Jyvmo-A",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.7888034,
            "lng": -81.3484065
        }
    }, {
        "title": "Northwood Health Systems",
        "street": "353 American Way",
        "city": "Weirton",
        "state": "WV",
        "zip": 26062,
        "formatted_phone_number": "(304) 217-3050",
        "website": "http://www.northwoodhealth.com/",
        "vicinity": "353 American Way, Weirton",
        "place_id": "ChIJw-TBD0U3NIgRvhD9Cg24a9I",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 40.3959407,
            "lng": -80.5312335
        }
    }, {
        "title": "Northwood Health Systems",
        "street": "2121 Eoff Street",
        "city": "Wheeling",
        "state": "WV",
        "zip": 26003,
        "formatted_phone_number": "(304) 234-3570",
        "website": "http://www.northwoodhealth.com/",
        "vicinity": "2121 Eoff Street, Wheeling",
        "place_id": "ChIJmeOrB4DZNYgRYgAFzJBvAW0",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 40.0596754,
            "lng": -80.7216988
        }
    }, {
        "title": "WV Health Right Clinic",
        "street": "1520 Washington Street, E.",
        "city": "Charleston",
        "state": "WV",
        "zip": 25311,
        "formatted_phone_number": "(304) 414-5930",
        "website": "http://www.wvhealthright.org/",
        "vicinity": "1520 Washington Street East, Charleston",
        "place_id": "ChIJX5I037ksT4gREp5JSbkAQQk",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.3429955,
            "lng": -81.6215388
        }
    }, {
        "title": "Seneca Health Services, Inc.",
        "street": "1305 Webster Road",
        "city": "Summersville",
        "state": "WV",
        "zip": 26206,
        "formatted_phone_number": "(304) 872-2659",
        "website": "http://www.shsinc.org/",
        "vicinity": "1 Stevens Road, Summersville",
        "place_id": "ChIJ1ezsW7SqTogRxsdwvDgYpBw",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.29089,
            "lng": -80.828496
        }
    }, {
        "title": "Parkersburg Correctional Center",
        "street": "225 Holiday Hills Drive",
        "city": "Parkersburg",
        "state": "WV",
        "zip": 26104,
        "formatted_phone_number": "(304) 420-2443",
        "website": "https://dcr.wv.gov/",
        "vicinity": "225 Holiday Hills Drive, Parkersburg",
        "place_id": "ChIJFQ5fRRS1SYgRybHHuQLGsxM",
        "opening_hours": {
            "weekday_text": ["Monday: 9:00 AM – 3:00 PM", "Tuesday: 9:00 AM – 3:00 PM", "Wednesday: 9:00 AM – 3:00 PM", "Thursday: 9:00 AM – 3:00 PM", "Friday: 9:00 AM – 3:00 PM", "Saturday: 9:00 AM – 3:00 PM", "Sunday: 9:00 AM – 3:00 PM"]
        },
        "position": {
            "lat": 39.2645531,
            "lng": -81.50191
        }
    }, {
        "title": "Northern Regional Jail and Correctional Facility",
        "street": "112 Northern Regional Correctional Dr",
        "city": "Moundsville",
        "state": "WV",
        "zip": 26041,
        "formatted_phone_number": "(304) 843-4067",
        "website": "http://www.rja.wv.gov/",
        "vicinity": "112 Northern Regional Correctional Drive, Moundsville",
        "place_id": "ChIJZbFCZ8HgNYgR2rX3vfqd1Kk",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 39.9090943,
            "lng": -80.7291377
        }
    }, {
        "title": "Western Regional Jail and Correctional Facility",
        "street": "One O'Hanlon Place",
        "city": "Barboursville",
        "state": "WV",
        "zip": 25504,
        "formatted_phone_number": "(304) 733-6821",
        "website": "http://www.rja.wv.gov/",
        "vicinity": "1 Ohanlon Place, Barboursville",
        "place_id": "ChIJfYROO64ARogRbL3jCUdzS-o",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.4185611,
            "lng": -82.2932624
        }
    }, {
        "title": "Tygart Valley Regional Jail and Correctional Facility",
        "street": "400 Abbey Road",
        "city": "Belington",
        "state": "WV",
        "zip": 26250,
        "formatted_phone_number": "(304) 637-0382",
        "website": "http://www.rja.wv.gov/Pages/Facility-Details.aspx?Facility=TVRJ",
        "vicinity": "400 Abbey Road, Belington",
        "place_id": "ChIJb8U1AsT6SogRRiOzUs-RTrA",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.9402848,
            "lng": -79.9515438
        }
    }, {
        "title": "Southwestern Regional Jail and Correctional Facility",
        "street": "1300 Gaston Caperton Drive",
        "city": "Holden",
        "state": "WV",
        "zip": 25625,
        "formatted_phone_number": "(304) 239-3032",
        "website": "http://www.rja.wv.gov/Pages/Facility-Details.aspx?Facility=SWRJ",
        "vicinity": "1300 Gaston Caperton Drive, Holden",
        "place_id": "ChIJt1jVp9l7T4gRmZVRhQ69qeI",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 37.8052941,
            "lng": -82.0957755
        }
    }, {
        "title": "Southern Regional Jail and Correctional Facility",
        "street": "1200 Airport Road",
        "city": "Beaver",
        "state": "WV",
        "zip": 25813,
        "formatted_phone_number": "(304) 256-6726",
        "website": "http://www.rja.wv.gov/Pages/Facility-Details.aspx?Facility=SRJ",
        "vicinity": "1200 Airport Road, Beaver",
        "place_id": "ChIJi-pHO6uNTogRxXiPHcfv32U",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 37.7709223,
            "lng": -81.1160206
        }
    }, {
        "title": "South Central Regional Jail and Correctional Facility",
        "street": "1001 Centre Way",
        "city": "Charleston",
        "state": "WV",
        "zip": 25309,
        "formatted_phone_number": "(304) 558-1336",
        "website": "http://www.rja.wv.gov/Pages/Facility-Details.aspx?Facility=SCRJ",
        "vicinity": "1001 Centre Way, Charleston",
        "place_id": "ChIJ24s94mYyT4gRNrkVCOVBh7g",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: 9:00 AM – 5:00 PM", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 38.3109982,
            "lng": -81.71022909999999
        }
    }, {
        "title": "Potomac Highlands Regional Jail and Correctional Facility",
        "street": "355 Dolan Drive",
        "city": "Augusta",
        "state": "WV",
        "zip": 26704,
        "formatted_phone_number": "(304) 496-1275",
        "website": "http://www.rja.wv.gov/Pages/Facility-Details.aspx?Facility=PHRJ",
        "vicinity": "355 Dolan Drive, Augusta",
        "place_id": "ChIJ7Y4IXX2CtYkRWjT8-USYy2A",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 39.30205979999999,
            "lng": -78.64717929999999
        }
    }, {
        "title": "North Central Regional Jail and Correctional Facility",
        "street": "1 Lois Lane",
        "city": "Greenwood",
        "state": "WV",
        "zip": 26415,
        "formatted_phone_number": "(304) 873-1384",
        "website": "https://dcr.wv.gov/facilities/Pages/prisons-and-jails/ncrjcf.aspx",
        "vicinity": "#1 Lois Lane, Greenwood",
        "place_id": "ChIJExcKcpWJSogRNUTGS-y29cg",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 39.2795018,
            "lng": -80.8504136
        }
    }, {
        "title": "Eastern Regional Jail and Correctional Facility",
        "street": "94 Grapevine Road",
        "city": "Martinsburg",
        "state": "WV",
        "zip": 25405,
        "formatted_phone_number": "(304) 267-0046",
        "website": "http://www.rja.wv.gov/Pages/Facility-Details.aspx?Facility=ERJ",
        "vicinity": "94 Grapevine Road, Martinsburg",
        "place_id": "ChIJc7h4BIoByokRo90ZCaqUUbU",
        "opening_hours": {
            "weekday_text": ["Monday: Closed", "Tuesday: 1:00 – 3:00 PM", "Wednesday: 1:00 – 3:00 PM", "Thursday: 1:00 – 3:00 PM", "Friday: 1:00 – 3:00 PM", "Saturday: 8:00 – 11:00 AM, 1:00 – 3:00 PM", "Sunday: Closed"]
        },
        "position": {
            "lat": 39.4359416,
            "lng": -77.95636999999999
        }
    }, {
        "title": "Central Regional Jail and Correctional Facility",
        "street": "1255 Dyer Hill Road",
        "city": "Sutton",
        "state": "WV",
        "zip": 26601,
        "formatted_phone_number": "(304) 765-7904",
        "website": "http://www.rja.wv.gov/Pages/default.aspx",
        "vicinity": "1255 Dyer Hill Road, Sutton",
        "place_id": "ChIJKbj412HbS4gRHBSy5KK9MxA",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 4:00 PM", "Tuesday: 8:00 AM – 4:00 PM", "Wednesday: 8:00 AM – 4:00 PM", "Thursday: 8:00 AM – 4:00 PM", "Friday: 8:00 AM – 4:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.697725,
            "lng": -80.65717769999999
        }
    }, {
        "title": "Southern Highlands",
        "street": "200 12th Street Extension",
        "city": "Princeton",
        "state": "WV",
        "zip": 24740,
        "formatted_phone_number": "(304) 425-9541",
        "website": "http://shcmhc.com/",
        "vicinity": "200 12th Street Ext., Princeton",
        "place_id": "ChIJjUBDJbdrTogRg8gpwOONiDg",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 37.3625473,
            "lng": -81.1152786
        }
    }, {
        "title": "Prestera Center",
        "street": "1113 W. Washington Street",
        "city": "Charleston",
        "state": "WV",
        "zip": 25302,
        "formatted_phone_number": "(304) 341-0511",
        "website": "https://www.prestera.org/",
        "vicinity": "511 Morris Street, Charleston",
        "place_id": "ChIJhWpG4sksT4gRDT8vIF8nSy0",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 4:30 PM", "Tuesday: 8:00 AM – 4:30 PM", "Wednesday: 8:00 AM – 4:30 PM", "Thursday: 8:00 AM – 4:30 PM", "Friday: 8:00 AM – 4:30 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.3482265,
            "lng": -81.6257997
        }
    }, {
        "title": "Prestera Center",
        "street": "One Prestera Way",
        "city": "Huntington",
        "state": "WV",
        "zip": 25705,
        "formatted_phone_number": "(304) 525-7851",
        "website": "https://www.prestera.org/",
        "vicinity": "3375 US Route 60 East, Huntington",
        "place_id": "ChIJC41wf2YHRogRHL0r_Zmn7bc",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.4105003,
            "lng": -82.3737175
        }
    },  {
        "title": "Clarksburg Comprehensive Treatment Center",
        "street": "706 Oakmound Road",
        "city": "Clarksburg",
        "state": "WV",
        "zip": 25301,
        "formatted_phone_number": "(304) 969-5072",
        "website": "http://www.northwestvirginiactc.com/location/clarksburg/?utm_source=Yext&utm_medium=organic&utm_campaign=listing&utm_term=brand",
        "vicinity": "706 Oak Mound Road, Clarksburg",
        "place_id": "ChIJQVsHmBtqSogRHOIFmINf8Tk",
        "opening_hours": {
            "weekday_text": ["Monday: 5:30 AM – 2:30 PM", "Tuesday: 5:30 AM – 2:30 PM", "Wednesday: 5:30 AM – 2:30 PM", "Thursday: 5:30 AM – 2:30 PM", "Friday: 5:30 AM – 2:30 PM", "Saturday: 6:00 – 8:30 AM", "Sunday: 6:00 – 8:30 AM"]
        },
        "position": {
            "lat": 39.265571,
            "lng": -80.372936
        }
    },  {
        "title": "Wheeling Comprehensive Treatment Center",
        "street": "40 Orrs Lane",
        "city": "Triadelphia",
        "state": "WV",
        "zip": 26059,
        "formatted_phone_number": "(304) 909-7759",
        "website": "http://www.northwestvirginiactc.com/location/wheeling/?utm_source=Yext&utm_medium=organic&utm_campaign=listing&utm_term=brand",
        "vicinity": "40 Orrs Lane, Triadelphia",
        "place_id": "ChIJg4diEyrFNYgRcc4Y0ZDGSdU",
        "opening_hours": {
            "weekday_text": ["Monday: 5:00 AM – 3:30 PM", "Tuesday: 5:00 AM – 3:30 PM", "Wednesday: 5:00 – 10:00 AM", "Thursday: 5:00 AM – 3:30 PM", "Friday: 5:00 AM – 3:30 PM", "Saturday: 5:00 – 9:00 AM", "Sunday: 5:00 – 9:00 AM"]
        },
        "position": {
            "lat": 40.0642415,
            "lng": -80.5711141
        }
    }, {
        "title": "Williamson Comprehensive Treatment Center",
        "street": "1609 3rd Avenue",
        "city": "Williamson",
        "state": "WV",
        "zip": 25661,
        "formatted_phone_number": "(304) 443-4562",
        "website": "http://www.swvirginiactc.com/location/williamson/?utm_source=Yext&utm_medium=organic&utm_campaign=listing&utm_term=brand",
        "vicinity": "1609 West 3rd Avenue, Williamson",
        "place_id": "ChIJySMiatB-RYgRCR7uJiVcfPg",
        "opening_hours": {
            "weekday_text": ["Monday: 5:00 AM – 2:30 PM", "Tuesday: 5:00 AM – 2:30 PM", "Wednesday: 5:00 AM – 1:00 PM", "Thursday: 5:00 AM – 2:30 PM", "Friday: 5:00 AM – 2:30 PM", "Saturday: 6:00 – 10:00 AM", "Sunday: 6:00 – 9:00 AM"]
        },
        "position": {
            "lat": 37.675204,
            "lng": -82.29218709999999
        }
    }, {
        "title": "Parkersburg Comprehensive Treatment Center",
        "street": "184 Holiday Hills Drive",
        "city": "Parkersburg",
        "state": "WV",
        "zip": 26104,
        "formatted_phone_number": "(304) 699-4233",
        "website": "http://www.northwestvirginiactc.com/location/parkersburg/?utm_source=Yext&utm_medium=organic&utm_campaign=listing&utm_term=brand",
        "vicinity": "184 Holiday Hills Drive, Parkersburg",
        "place_id": "ChIJa6DtiTG1SYgR8RXBk6bBAj4",
        "opening_hours": {
            "weekday_text": ["Monday: 6:00 AM – 1:00 PM", "Tuesday: 6:00 AM – 1:00 PM", "Wednesday: 6:00 – 11:00 AM", "Thursday: 6:00 AM – 1:00 PM", "Friday: 6:00 AM – 1:00 PM", "Saturday: 6:30 – 9:00 AM", "Sunday: 6:00 – 8:00 AM"]
        },
        "position": {
            "lat": 39.2645525,
            "lng": -81.5028396
        }
    }, {
        "title": "Mountain State Recovery Center",
        "street": "102 Patrick Street Plz",
        "city": "Charleston",
        "state": "WV",
        "zip": 25387,
        "formatted_phone_number": "(304) 233-3200",
        "website": "http://www.msrcwv.com/",
        "vicinity": "102 Patrick Street Plaza, Charleston",
        "place_id": "ChIJXTJc_JPTSIgRfVWhWWQqJtU",
        "opening_hours": {
            "weekday_text": ["Monday: 7:00 AM – 7:00 PM", "Tuesday: 7:00 AM – 4:00 PM", "Wednesday: 7:00 AM – 7:00 PM", "Thursday: 7:00 AM – 4:00 PM", "Friday: 7:00 AM – 4:00 PM", "Saturday: 10:00 AM – 4:00 PM", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.367842,
            "lng": -81.6681284
        }
    }, {
        "title": "Huntington Comprehensive Treatment Center",
        "street": "135 4th Avenue",
        "city": "Huntington",
        "state": "WV",
        "zip": 25701,
        "formatted_phone_number": "(304) 244-5458",
        "website": "http://www.swvirginiactc.com/location/huntington/?utm_source=Yext&utm_medium=organic&utm_campaign=listing&utm_term=brand",
        "vicinity": "135 4th Avenue, Huntington",
        "place_id": "ChIJQarEVOYJRogReIZ9f-i44lA",
        "opening_hours": {
            "weekday_text": ["Monday: 5:00 AM – 2:30 PM", "Tuesday: 5:00 AM – 2:30 PM", "Wednesday: 6:00 – 11:30 AM", "Thursday: 5:00 AM – 2:30 PM", "Friday: 5:00 AM – 2:30 PM", "Saturday: 6:00 – 9:45 AM", "Sunday: 6:00 – 9:45 AM"]
        },
        "position": {
            "lat": 38.4183489,
            "lng": -82.4563596
        }
    }, {
        "title": "FMRS Mental Health Council",
        "street": "Middle Street",
        "city": "Union",
        "state": "WV",
        "zip": 24983,
        "formatted_phone_number": "(304) 772-5452",
        "website": "http://www.fmrs.org/",
        "vicinity": "Middle Street, Union",
        "place_id": "ChIJqZy71lfLTYgR7vcDiOGHWLg",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 37.5905326,
            "lng": -80.543543
        }
    }, {
        "title": "FMRS Health Systems",
        "street": "198 Pleasant Street",
        "city": "Hinton",
        "state": "WV",
        "zip": 25951,
        "formatted_phone_number": "(304) 466-3899",
        "website": "https://www.fmrs.org/",
        "vicinity": "198 Pleasant Street, Hinton",
        "place_id": "ChIJn235G9SATogRS9GcvTQFc6o",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 37.6643993,
            "lng": -80.89347610000002
        }
    }, {
        "title": "FMRS Health Systems",
        "street": "209 West Maple Avenue",
        "city": "Fayetteville",
        "state": "WV",
        "zip": 25840,
        "formatted_phone_number": "(304) 574-2100",
        "website": "https://www.fmrs.org/",
        "vicinity": "209 West Maple Avenue, Fayetteville",
        "place_id": "ChIJkckBZd6-TogRJvTrzBgfWxc",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.0507065,
            "lng": -81.1043924
        }
    }, {
        "title": "FMRS Health Systems",
        "street": "101 S Eisenhower Drive",
        "city": "Beckley",
        "state": "WV",
        "zip": 25801,
        "formatted_phone_number": "(304) 256-7100",
        "website": "http://www.fmrs.org/",
        "vicinity": "101 South Eisenhower Drive, Beckley",
        "place_id": "ChIJk16JpNPyTogRg5iihOM1zGI",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 5:00 PM", "Tuesday: 8:00 AM – 5:00 PM", "Wednesday: 8:00 AM – 5:00 PM", "Thursday: 8:00 AM – 5:00 PM", "Friday: 8:00 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 37.7823556,
            "lng": -81.167127
        }
    }, {
        "title": "Chesapeake Healthcare Center",
        "street": "11950 MacCorkle Avenue, SE",
        "city": "Chesapeake",
        "state": "WV",
        "zip": 25315,
        "formatted_phone_number": "(304) 220-2111",
        "website": "https://chesapeakehealthcarecenter.com/",
        "vicinity": "11950 McCorkle Avenue, Chesapeake",
        "place_id": "ChIJ32UTUfApT4gRme_CwnWFAOw",
        "opening_hours": {
            "weekday_text": ["Monday: 8:00 AM – 4:30 PM", "Tuesday: 8:00 AM – 4:30 PM", "Wednesday: 8:00 AM – 4:30 PM", "Thursday: 8:00 AM – 6:00 PM", "Friday: 8:00 AM – 4:30 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.22773919999999,
            "lng": -81.5414299
        }
    }, {
        "title": "Charleston Comprehensive Treatment Center",
        "street": "2157 Greenbrier Street",
        "city": "Charleston",
        "state": "WV",
        "zip": 25311,
        "formatted_phone_number": "(304) 553-0051",
        "website": "http://www.swvirginiactc.com/location/charleston/?utm_source=Yext&utm_medium=organic&utm_campaign=listing&utm_term=brand",
        "vicinity": "2157 Greenbrier Street, Charleston",
        "place_id": "ChIJe3nvKv3USIgRrp_iMoL7GxQ",
        "opening_hours": {
            "weekday_text": ["Monday: 5:30 AM – 4:00 PM", "Tuesday: 5:30 AM – 4:00 PM", "Wednesday: 5:30 AM – 4:00 PM", "Thursday: 5:30 AM – 4:00 PM", "Friday: 5:30 AM – 4:00 PM", "Saturday: 6:00 – 9:00 AM", "Sunday: 6:00 – 9:00 AM"]
        },
        "position": {
            "lat": 38.3792544,
            "lng": -81.56510980000002
        }
    }, {
        "title": "CAMC Teays Valley Hospital - MAT Treatment",
        "street": "1400 Hospital Drive",
        "city": "Hurricane",
        "state": "WV",
        "zip": 25526,
        "formatted_phone_number": "(304) 757-1700",
        "website": "http://camc.org/tvh",
        "vicinity": "1400 Hospital Drive, Hurricane",
        "place_id": "ChIJ7ZQ02Ae0SIgRP1GaKwNADrM",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 38.4517194,
            "lng": -81.9523723
        }
    }, {
        "title": "CAMC Women and Children's Hospital",
        "street": "800 Pennsylvania Avenue, N",
        "city": "Charleston",
        "state": "WV",
        "zip": 25302,
        "formatted_phone_number": "(304) 388-5432",
        "website": "http://camc.org/wch",
        "vicinity": "800 Pennsylvania Avenue North, Charleston",
        "place_id": "ChIJvyBLOizTSIgRqCVm-MOTpWs",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 38.3593613,
            "lng": -81.6383646
        }
    }, {
        "title": "CAMC General Hospital - MAT Treatment",
        "street": "501 Morris Street",
        "city": "Charleston",
        "state": "WV",
        "zip": 25301,
        "formatted_phone_number": "(304) 388-5432",
        "website": "http://camc.org/gh",
        "vicinity": "501 Morris Street, Charleston",
        "place_id": "ChIJ36nY48ksT4gRrzYKfb2Tsos",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 38.3476492,
            "lng": -81.6271407
        }
    }, {
        "title": "CAMC Memorial Hospital - MAT Treatment",
        "street": "3200 MacCorkle Avenue, SE",
        "city": "Charleston",
        "state": "WV",
        "zip": 25304,
        "formatted_phone_number": "(304) 388-5432",
        "website": "http://camc.org/mh",
        "vicinity": "3200 MacCorkle Avenue Southeast, Charleston",
        "place_id": "ChIJn9vBImArT4gRYAKpwmP_eXk",
        "opening_hours": {
            "weekday_text": ["Monday: Open 24 hours", "Tuesday: Open 24 hours", "Wednesday: Open 24 hours", "Thursday: Open 24 hours", "Friday: Open 24 hours", "Saturday: Open 24 hours", "Sunday: Open 24 hours"]
        },
        "position": {
            "lat": 38.3298276,
            "lng": -81.602921
        }
    }, {
        "title": "Cabin Creek Health Systems",
        "street": "5722 Cabin Creek Road",
        "city": "Dawes",
        "state": "WV",
        "zip": 25054,
        "formatted_phone_number": "(304) 734-2040",
        "vicinity": "5722 Cabin Creek Road, Dawes",
        "place_id": "ChIJ21Vj3rzZTogRTrPEd1sY9a4",
        "opening_hours": {
            "weekday_text": []
        },
        "position": {
            "lat": 38.1410588,
            "lng": -81.45326159999999
        }
    }, {
        "title": "Cabin Creek Health Systems",
        "street": "6135 Sissonville Drive",
        "city": "Sissonville",
        "state": "WV",
        "zip": 25312,
        "formatted_phone_number": "(304) 984-1576",
        "website": "http://cabincreekhealth.com/",
        "vicinity": "6135 Sissonville Drive, Charleston",
        "place_id": "ChIJ0dMoXVDQSIgRdw1zLIHxdXc",
        "opening_hours": {
            "weekday_text": ["Monday: 7:30 AM – 5:00 PM", "Tuesday: 7:30 AM – 5:00 PM", "Wednesday: 7:30 AM – 5:00 PM", "Thursday: 7:30 AM – 5:00 PM", "Friday: 7:30 AM – 5:00 PM", "Saturday: Closed", "Sunday: Closed"]
        },
        "position": {
            "lat": 38.4710276,
            "lng": -81.6715524
        }
    }, {
        "title": "Beckley Comprehensive Treatment Center",
        "street": "175 Philpot Lane",
        "city": "Beaver",
        "state": "WV",
        "zip": 25813,
        "formatted_phone_number": "(304) 223-3373",
        "website": "http://www.swvirginiactc.com/location/beckley/?utm_source=Yext&utm_medium=organic&utm_campaign=listing&utm_term=brand",
        "vicinity": "175 Philpot Lane, Beaver",
        "place_id": "ChIJbX8qjpuNTogRXfmdHHWbpzk",
        "opening_hours": {
            "weekday_text": ["Monday: 5:00 AM – 2:45 PM", "Tuesday: 5:00 AM – 2:45 PM", "Wednesday: 5:00 AM – 2:45 PM", "Thursday: 5:00 – 11:00 AM, 1:00 – 2:45 PM", "Friday: 5:00 AM – 2:45 PM", "Saturday: 5:00 – 9:15 AM", "Sunday: 6:00 – 8:45 AM"]
        },
        "position": {
            "lat": 37.798087,
            "lng": -81.11337999999999
        }
    }, {
    "title": "Shenandoah",
    "street": "53 W. Main Street",
    "city": "Romney",
    "state": "WV",
    "zip": 26757,
    "vicinity": "53 W. Main Street, Romney",
    "place_id": "EiM1MyBXIE1haW4gU3QsIFJvbW5leSwgV1YgMjY3NTcsIFVTQSIaEhgKFAoSCfe3ij3le7WJEb7-9x2UX0K3EDU",
    "opening_hours": {
        "weekday_text": []
    },
    "position": {
        "lat": 39.3423778,
        "lng": -78.75693749999999
    }
}, {
    "title": "Shenandoah",
    "street": "3334 New Creek Highway",
    "city": "New Creek",
    "state": "WV",
    "zip": 26743,
    "vicinity": "3334 New Creek Highway, New Creek",
    "place_id": "ChIJsejX16lgtYkR9VBr0ViGIiQ",
    "opening_hours": {
        "weekday_text": []
    },
    "position": {
        "lat": 39.3860923,
        "lng": -79.0192555
    }
}, {
    "title": "Prestera Center",
    "street": "2120 Valley Drive",
    "city": "Point Pleasant",
    "state": "WV",
    "zip": 25550,
    "formatted_phone_number": "(304) 525-7851",
    "vicinity": "2120 Valley Drive, Point Pleasant",
    "place_id": "ChIJKW_CJX2cSIgROl5N7tbiUK0",
    "opening_hours": {
        "weekday_text": []
    },
    "position": {
        "lat": 38.846319,
        "lng": -82.13651800000001
    }
}, {
    "title": "Prestera Center",
    "street": "195 Dingess Street",
    "city": "Logan",
    "state": "WV",
    "zip": 25601,
    "formatted_phone_number": "(304) 752-0714",
    "vicinity": "197 Dingess Street, Logan",
    "place_id": "ChIJw_dHm7NxT4gRnT2QxBJBTx0",
    "opening_hours": {
        "weekday_text": []
    },
    "position": {
        "lat": 37.848196,
        "lng": -81.9939034
    }
}, {
    "title": "Marshall University",
    "street": "467 Main Street",
    "city": "Madison",
    "state": "WV",
    "zip": 25310,
    "website": "https://swvhs.org/portfolio-items/madison-medical/",
    "vicinity": "467 Main Street, Madison",
    "hide_directions": true,
    "opening_hours": {
        "weekday_text": []
    },
    "position": {
        "lat": 38.0620336,
        "lng": -81.8252116
    }
}, {
    "title": "Shenandoah - PHG Grant County Office",
    "street": "7 Mountain View Street",
    "city": "Petersburg",
    "state": "WV",
    "zip": 26884,
    "website": "http://potomachighlandsguild.com/locations.html",
    "vicinity": "7 Mountain View Street, Petersburg",
    "hide_directions": true,
    "opening_hours": {
        "weekday_text": []
    },
    "position": {
        "lat": 38.9940928,
        "lng": -79.1279141
    }
}];



