import { render, staticRenderFns } from "./google-map.vue?vue&type=template&id=0247bc61&"
import script from "./google-map.vue?vue&type=script&lang=js&"
export * from "./google-map.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/backtolifewv.org/public/wp-content/themes/wvdhhr_btl/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0247bc61')) {
      api.createRecord('0247bc61', component.options)
    } else {
      api.reload('0247bc61', component.options)
    }
    module.hot.accept("./google-map.vue?vue&type=template&id=0247bc61&", function () {
      api.rerender('0247bc61', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/google-map.vue"
export default component.exports